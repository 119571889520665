import React, { useEffect, useState } from "react";

// Styles
import VoteDetailByVotersContainer from "./VoteDetailByVoters.styles";

// Interface
import {DealVote, GrowthAnswer, GrowthQuestion, DealTeam, VoteResultsByMember, VoteResult} from "assets/interfaces/growth_voting";

// Components
import {createVoteSummariesByMembers, createVoteSummariesByQuestions} from "utils/growth_voting.utils";
import AnswerResult from "../AnswerResult/AnswerResult";

// Enums
import { EnumGrowthAnswerTypes } from "assets/enums/growth_voting";
import { create, sum } from "lodash";

interface Props {
    vote: DealVote
    questions: GrowthQuestion[]
    answeres: GrowthAnswer[]
    team: DealTeam|undefined // vote team
}

const VoteDetailByVoters = ({vote, questions, answeres, team}: Props) => {
    const [summaryByMembers, setSummariesByMembers] = useState<VoteResultsByMember[]>([]);
    const [summaryByQuestions, setSummariesByQuestions] = useState<VoteResult[]>([]);

    const createSummariesByMember = () => {
        if (!team) throw("Team is not defined");

        const summaries = createVoteSummariesByMembers(questions, answeres, team);
        setSummariesByMembers(summaries);
    }

    const createSummariesByQuestions = () => {
        const summaries = createVoteSummariesByQuestions(questions, answeres);
        setSummariesByQuestions(summaries);
    }

    useEffect(() => {
        if (answeres.length > 0 && questions.length > 0) {
            createSummariesByMember();
            createSummariesByQuestions();
        }

    }, [questions, answeres]);


    return (
        <VoteDetailByVotersContainer>
            <table className="table-details table">
            {summaryByMembers.length > 0 && <thead>
                    <tr>
                        <td className={"name"}></td>
                        {questions.length > 0 && questions.map((question, index) => (
                            <td className={"title"}>{question.question}</td>
                            
                    ))}
                    </tr>
                </thead>}
                <tbody>
                {summaryByQuestions.length > 0 &&
                    <tr>
                        <td className={"result result-name"}>RESULT</td>
                       {summaryByQuestions.map((summary, index) => (
                           <td  className={"result"}>
                               {summary.question.answer_type === EnumGrowthAnswerTypes.Boolean &&
                                   <div>
                                       {<AnswerResult result={summary.result} answerType={EnumGrowthAnswerTypes.Boolean} numberOfAnswers={1} summary={true} />}
                                   </div>
                               }
                               {summary.question.answer_type === EnumGrowthAnswerTypes.Percentage &&
                                   <div>
                                       {summary.result && <AnswerResult result={summary.result} answerType={EnumGrowthAnswerTypes.Percentage} numberOfAnswers={1} />}
                                   </div>
                               }
                               {summary.question.answer_type === EnumGrowthAnswerTypes.Rating_1_5 &&
                                   <div>
                                       {summary.result && <AnswerResult result={summary.result} answerType={EnumGrowthAnswerTypes.Rating_1_5} numberOfAnswers={1} />}
                                   </div>
                               }
                           </td>
                       ))}
                    </tr>
                }
                {summaryByMembers.length > 0 &&
                    summaryByMembers.map((summary, index) => (
                        summary.questions.some(q => q.answer) && <tr>
                            <td key={index} className={"name"}>{summary.member.name}</td>
                            {summary.questions.length > 0 && summary.questions.map((question, index) => (
                                <td key={index} className={question.name.toLowerCase().includes("overall") ? "overall" : ""}>
                                    {question.answer_type === EnumGrowthAnswerTypes.Boolean &&
                                        <div>
                                            {question.answer && <AnswerResult result={question.answer.answer_bool} answerType={EnumGrowthAnswerTypes.Boolean} numberOfAnswers={1} summary={false}/>}
                                        </div>
                                    }
                                    {question.answer_type === EnumGrowthAnswerTypes.Percentage &&
                                        <div>
                                            {question.answer && <AnswerResult result={question.answer.answer_percent} answerType={EnumGrowthAnswerTypes.Percentage} numberOfAnswers={1} />}
                                        </div>
                                    }
                                    {question.answer_type === EnumGrowthAnswerTypes.Rating_1_5 &&
                                        <div>
                                            {question.answer && <AnswerResult result={question.answer.answer_rating} answerType={EnumGrowthAnswerTypes.Rating_1_5} numberOfAnswers={1} />}
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                    {summaryByMembers.length === 0 && <tr><td colSpan={questions.length + 1}>No answer</td></tr>}
                </tbody>
            </table>


        </VoteDetailByVotersContainer>
    );
};

export default VoteDetailByVoters
